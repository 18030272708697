<template>
    <el-menu class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" :collapse-transition="false"
        active-text-color="#ffd04b">
        <h3>AIGC</h3>
        <el-menu-item :index="item.path+''" v-for="item in list" :key="item.label">
            <component class="icons" :is="item.icon"></component>
            <!-- <span>{{item.label}}</span> -->
            <el-link :href="item.path" type="info" :underline="false">{{item.label}}</el-link>
        </el-menu-item>
    </el-menu>
</template>


<script lang="ts">
export default {
    setup() {
        const list = [
            {
                path: '/#/wenxin',
                name: 'wenxin',
                label: 'AI点评-录音',
                icon: 'Avatar'
            },
            {
                label: '文心对话',
                name: 'other',
                icon: 'ChatDotRound',
                path: '/#/talk'
            },
            {
                label: '知识域',
                name: 'other',
                icon: 'Help',
                path: '/#/knowledge'
            },
            {
                label: 'PoseNet',
                name: 'other',
                icon: 'User',
                path: '/#/posenet'
            },
        ];
        return {
            list,
        }
    }
}
</script>

<style lang="less" scoped>
.icons {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}
.el-menu-vertical-demo {
    width: 100%;
    height: 100%;
    border-right: none;
    h3 {
        color: #fff;
        text-align: center;
        margin-top: 10px;
    }
}

el-menu-item {
    margin-bottom: 0px;
}
</style>
