import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/wenxin',
    name: '文心点评',
    component: () => import(/* webpackChunkName: "点评" */ '../views/WenxinView.vue')
  },
  {
    path: '/talk',
    name: 'ErnieBot',
    component: () => import(/* webpackChunkName: "对话" */ '../views/WenxinTalk.vue')
  },
  {
    path: '/ernie-bot-turbo',
    name: 'ErnieBotTurbo',
    component: () => import(/* webpackChunkName: "对话" */ '../views/ErnieBotTurboTalk.vue')
  },
  {
    path: '/qianwen',
    name: '通义千问',
    component: () => import(/* webpackChunkName: "对话" */ '../views/TongyiTalk.vue')
  },
  {
    path: '/knowledge',
    name: '文心知识域',
    component: () => import(/* webpackChunkName: "知识域" */ '../views/KnowledgeTalk.vue')
  },
  {
    path: '/posenet',
    name: 'PoseNet',
    component: () => import(/* webpackChunkName: "PoseNet" */ '../views/PoseNet.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
