<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="200px">
        <CommonAside></CommonAside>
      </el-aside>
      <el-container>
        <el-header>
          <nav>
            <!-- <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link> | -->
            <router-link to="/wenxin">录音点评</router-link> |
            <router-link to="/talk">对话-文心bot</router-link> | 
            <router-link to="/ernie-bot-turbo">对话-文心turbo</router-link> | 
            <router-link to="/qianwen">对话-阿里千问</router-link> | 
            <router-link to="/knowledge">知识域问答</router-link> | 
            <router-link to="/posenet">PoseNet</router-link>
          </nav>
        </el-header>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>  
</template>

<script lang="ts">
import CommonAside from "./components/CommonAside.vue"
export default {
    components: {
        CommonAside,
    }
}
</script>
<style lang="scss">
.common-layout {
  height: 100%;
    &>.el-container {
        height: 100%;
        &>.el-aside {
            height: 100%;
            background: #545c64;
        }
        &>.el-container {
            &>.el-header {
                padding: 0%;
            }
        }
    }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
