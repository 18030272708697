import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonAside = _resolveComponent("CommonAside")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, { width: "200px" }, {
          default: _withCtx(() => [
            _createVNode(_component_CommonAside)
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createElementVNode("nav", null, [
                  _createVNode(_component_router_link, { to: "/wenxin" }, {
                    default: _withCtx(() => [
                      _createTextVNode("录音点评")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" | "),
                  _createVNode(_component_router_link, { to: "/talk" }, {
                    default: _withCtx(() => [
                      _createTextVNode("对话-文心bot")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" | "),
                  _createVNode(_component_router_link, { to: "/ernie-bot-turbo" }, {
                    default: _withCtx(() => [
                      _createTextVNode("对话-文心turbo")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" | "),
                  _createVNode(_component_router_link, { to: "/qianwen" }, {
                    default: _withCtx(() => [
                      _createTextVNode("对话-阿里千问")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" | "),
                  _createVNode(_component_router_link, { to: "/knowledge" }, {
                    default: _withCtx(() => [
                      _createTextVNode("知识域问答")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" | "),
                  _createVNode(_component_router_link, { to: "/posenet" }, {
                    default: _withCtx(() => [
                      _createTextVNode("PoseNet")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}