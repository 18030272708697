import { createApp } from 'vue'
import App from './App.vue'
// 导入Element-Plus插件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// Router插件
import router from './router'

//element-plus el-icon
//https://element-plus.org/zh-CN/component/icon.html
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//axios插件
//import axios from 'axios'
//axios.defaults.baseURL='/api'

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
//app.use(axios)
// vue启动axios
//app.config.globalProperties.$http = axios

//导入所有element-plus el-icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
